import styled from 'styled-components';
import { wFace } from '../../images';
import { Card } from '../../styles';

export const Block = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	@media screen and (max-width: 414px) {
		margin-bottom: 0;
	}
`;

export const Face = styled.div`
	width: 70px;
	height: 70px;
	background-image: url(${wFace});
	background-repeat: no-repeat;
	background-size: 100%;
`;
export const T = styled.h2`
	color: #ffffff;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
`;

export const FormContainer = styled(Card)`
	width: 488px;
	height: 356px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 25px 50px 54px;

	@media screen and (max-width: 414px) {
		width: unset;
		height: unset;
		min-width: 300px;
		min-height: 295px;
		padding: 20px 25px 54px;
	}
`;
export const H = styled.h1`
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 45px;
	text-align: center;
	padding: 0;
	margin: 0 0 25px 0;
`;

export const P = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	padding: 0;
`;

export const LoginForm = styled.form`
	width: 100%;

	& > input {
		margin: 0 0 30px 0;
	}
`;
