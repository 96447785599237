import React, { useState, useEffect } from 'react';
import DetectRTC from 'detectrtc';

const withCamera = WrappedComponent => props => {
	const [cameraAvailable, setCamera] = useState(true);

	useEffect(() => {
		DetectRTC.load(() => {
			setCamera(() => DetectRTC.hasWebcam);
		});
	}, []);

	return <WrappedComponent {...props} cameraAvailable={cameraAvailable} />;
};

export default withCamera;
