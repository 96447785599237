import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = () => {
	return (
		<CircleWrapper>
			<Circle />
			<CircleFiller />
		</CircleWrapper>
	);
};

export default Spinner;

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const size = '50px';
const animationTime = '3.33s';
const circleColor = '46,110,255';

const CircleWrapper = styled.div`
	position: relative;
	display: block;
	height: ${size};
	width: ${size};
	background: #ffffff;
	border-radius: 50%;
`;

const Circle = styled.div`
	width: 100%;
	height: 100%;
	animation: ${rotate} ${animationTime} linear infinite;

	&:after,
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 50%;
	}

	&:before {
		border-radius: ${size} ${size} 0 0;
		background-image: linear-gradient(to right, rgba(${circleColor}, 0.5), ${circleColor});
	}

	&:after {
		top: 50%;
		border-radius: 0 0 ${size} ${size};
		background-image: linear-gradient(to right, rgba(${circleColor}, 0.5), transparent);
	}
`;

const CircleFiller = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	height: 90%;
	width: 90%;
	background: #fff;
	border-radius: 50%;
`;
