import React from 'react';
import styled from 'styled-components';
import { Header } from '..';
import { BG } from '../../styles';

const Layout = props => {
	return (
		<>
			<L>
				<Header whiteLogo={props.whiteLogo} control={props.control} controlAction={props.controlAction} />
				{props.children}
			</L>
			{props.whiteLogo && <BG />}
		</>
	);
};

export default Layout;

const L = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: center;
	box-sizing: border-box;
	flex-shrink: 0;
	padding: 14px 35px;
	@media screen and (max-width: 414px) {
		padding: 14px 17px;
	}
`;
