import React from 'react';
import styled from 'styled-components';
import { MethodItem } from '../../components';
import { Card } from '../../styles';

const MethodWrapper = ({ m, ...props }) => {
	const Method = m.component;
	return (
		<div>
			<MethodItem method={m} active />
			<MethodCard>{Method && <Method />}</MethodCard>
		</div>
	);
};

export default MethodWrapper;

const MethodCard = styled(Card)`
	width: 100%;
	height: 575px;

	padding: 25px 50px 30px;
	@media screen and (max-width: 414px) {
		padding: 25px 25px 20px;
		height: 504px;
	}
`;
