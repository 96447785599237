import React from 'react';
import { connect } from 'react-redux';
import { chooseMethod, resetMethod } from '../../store/actions';
import styled from 'styled-components';
import { Layout, MethodWrapper, CameraError, AwsRekognition, FaceApi } from '../../components';
import SideContent from './SideContent';
import MethodsList from './MethodsList';
import withCamera from '../../hocs/withCamera';

const methods = [
	{ name: 'Method 1', num: '1', component: AwsRekognition },
	{ name: 'Method 2a', num: '2', component: FaceApi },
	{ name: 'Method 2b', num: '3', component: null },
];

const AgeVerification = ({ method, chooseMethod, resetMethod, ...props }) => {
	const isMobile = window.matchMedia('(max-width: 414px)').matches;
	const isMethodChoosen = Object.keys(method).length;
	const clickAction = isMethodChoosen ? resetMethod : () => props.history.push('/');

	return (
		<Layout direction={'row'} control={isMobile} controlAction={clickAction}>
			<Content>
				<Side method={method ? 1 : 0}>
					<SideContent click={clickAction} />
				</Side>
				<Main>
					{props.cameraAvailable ? (
						isMethodChoosen ? (
							<MethodWrapper m={method} />
						) : (
							<MethodsList methods={methods} handler={chooseMethod} />
						)
					) : (
						<CameraError />
					)}
				</Main>
			</Content>
		</Layout>
	);
};

function mapStateToProps(state) {
	return {
		method: state.methodsReducer,
	};
}

export default connect(
	mapStateToProps,
	{ chooseMethod, resetMethod }
)(withCamera(AgeVerification));

const Content = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	@media screen and (max-width: 414px) {
		flex-direction: column;
	}
`;

const Side = styled.div`
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin-right: 27px;
	max-width: 289px;
	@media screen and (max-width: 414px) {
		height: 230px;
		margin-right: 0;
		display: ${({ method }) => (method ? 'none' : 'block')};
	}
`;

const Main = styled.div`
	width: 100%;
	height: 100%;
	max-width: 488px;
`;
