import styled from 'styled-components';
import { Card } from '../../styles';

export const H2 = styled.h2`
	font-family: Encode Sans Semi Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 45px;
	text-align: center;
	color: #ffffff;
`;

export const Cards = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	@media screen and (max-width: 414px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const C = styled(Card)`
	width: 220px;
	height: 260px;
	padding: 20px;
	margin: 0 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;

	@media screen and (max-width: 414px) {
		margin: 10px 0;
	}
`;

export const H = styled.h4`
	margin: 13px 0;
	padding: 0;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
`;

export const D = styled.p`
	margin: 0 0 20px 0;
	padding: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
`;
