import React, { useReducer } from 'react';
import styled from 'styled-components';
import TakePhoto from './TakePhoto';
import SendPhoto from './SendPhoto';
import Result from './Result';
import reducer from './reducer';

const steps = [TakePhoto, SendPhoto, Result];

const AwsRekognition = () => {
	const isMobile = window.matchMedia('(max-width: 414px)').matches;
	const [state, dispatch] = useReducer(reducer, { step: 0, img: '', b64Img: '', error: false, ageData: {} });
	const Step = steps[state.step];

	return (
		<Wrapper>
			<Step isMobile={isMobile} dispatch={dispatch} state={state} />
		</Wrapper>
	);
};

export default AwsRekognition;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
`;
