import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Button = ({ w, to, click, hideMob, removeBorder, icon, children }) => {
	if (to)
		return (
			<B w={w} as={Link} to={to}>
				<span>{children}</span>
			</B>
		);
	return (
		<B onClick={click} w={w} hideMob={hideMob} removeBorder={removeBorder}>
			{icon && <Icon icon={icon} />}
			{children}
		</B>
	);
};

export default Button;

Button.defaultProps = {
	click: () => {},
};

const Icon = styled.div`
	width: 20px;
	height: 20px;
	background-image: url(${({ icon }) => icon});
	background-repeat: no-repeat;
	background-size: 100%;
	margin-right: 10px;
`;

const B = styled.button`
	border: ${({ removeBorder }) => (removeBorder ? 'none' : '3px solid #2e6eff')};
	background-color: #ffffff;
	color: #2e6eff;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	width: ${({ w }) => w || '100%'};
	height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: initial;
	position: relative;
	@media screen and (max-width: 414px) {
		display: ${({ hideMob }) => (hideMob ? 'none' : 'flex')};
	}
`;
