import React, { useState } from 'react';
import { Layout, Input, Button } from '../../components';
import { FormContainer, H, P, LoginForm, Block, Face, T } from './Login';

const Login = props => {
	const [v, set] = useState('');

	const handleChange = e => {
		set(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (v === 'AVSSJL') {
			localStorage.isLoggedIn = true;
			props.history.push('/');
		} else {
			set('');
		}
	};

	return (
		<Layout whiteLogo>
			<Block>
				<Face />
				<T>Face Scan</T>
			</Block>
			<FormContainer>
				<div>
					<H>Login</H>
					<P>Please, enter your password to continue</P>
				</div>
				<LoginForm onSubmit={handleSubmit}>
					<Input
						placeholder='e.g. 5693'
						label='Enter Password'
						type='password'
						value={v}
						onChange={handleChange}
					/>
					<Button type='submit'>Login</Button>
				</LoginForm>
			</FormContainer>
		</Layout>
	);
};

export default Login;
