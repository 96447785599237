import { CHOOSE_METHOD, RESET_METHOD } from '../constants';
const initialState = {};

const methodsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHOOSE_METHOD:
			return { ...action.method };
		case RESET_METHOD:
			return {};
		default:
			return state;
	}
};

export default methodsReducer;
