import React from 'react';
import { MethodItem } from '../../components';

const MethodsList = props => {
	return (
		<>
			{props.methods.map(m => {
				return <MethodItem key={m.name} handler={props.handler} method={m} />;
			})}
		</>
	);
};

export default MethodsList;
