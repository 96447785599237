import React from 'react';
import { bFace } from '../../images';
import styled from 'styled-components';
import { Button } from '../../components';
import { Img } from '../../styles';

const SideContent = props => {
	return (
		<Wrap>
			<Div>
				<Img mW={'50px'} mH={'50px'} src={bFace} />
				<H4>Age Verification</H4>
			</Div>
			<H>Choose a Verification Method</H>
			<Button click={props.click} w={'155px'} hideMob>
				Back
			</Button>
		</Wrap>
	);
};

export default SideContent;

const Wrap = styled.div`
	width: 100%;
	height: 100%;
`;

const Div = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 414px) {
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
`;

const H4 = styled.h4`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin: 0.5em 0 1em;
	@media screen and (max-width: 414px) {
		margin: 0 15px;
	}
`;

const H = styled.h2`
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 45px;
	margin: 1em 0 50px;
	@media screen and (max-width: 414px) {
		font-size: 26px;
		line-height: 32px;
		margin: 1em 0 0;
	}
`;
