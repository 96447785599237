import React from 'react';
import styled from 'styled-components';

const Input = ({ label, placeholder, value, onChange, type }) => {
	return (
		<>
			<L htmlFor={label}>{label}</L>
			<I id={label} placeholder={placeholder} type={type} value={value} onChange={onChange} />
		</>
	);
};

export default Input;

const L = styled.label`
	display: block;
	margin-bottom: 5px;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
`;

const I = styled.input`
	border: 1px solid #2e6eff;
	box-sizing: border-box;
	padding: 14px 18px;
	width: 100%;
	height: 47px;
	color: #2e6eff;
	line-height: 20px;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
	outline: none;

	&::placeholder {
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		color: #2e6eff;
	}
`;
