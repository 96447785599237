import React from 'react';
import styled from 'styled-components';
import { Card, Img } from '../../styles';
import { bCamera } from '../../images';
import { Button } from '../../components';

const CameraError = () => {
	return (
		<Err>
			<Img src={bCamera} w={'66px'} mW={'66px'} h={'66px'} mH={'66px'} />
			<H>Camera was not found</H>
			<T>
				Please, connect camera to your device to use this method. Reload page after you connect the camera to
				try again
			</T>
			<Button click={() => window.location.reload()}>Reload Page</Button>
		</Err>
	);
};

export default CameraError;

const H = styled.h4`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	margin: 30px 0 15px;
`;
const T = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	margin: 0 0 50px;
`;

const Err = styled(Card)`
	width: 100%;
	height: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px 25px;
	@media screen and (max-width: 414px) {
		height: unset;
		min-height: 372px;
		justify-content: initial;
	}
`;
