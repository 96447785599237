import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ path, component: Component, ...props }) => {
	if (localStorage.isLoggedIn) {
		return <Route path={path} component={Component} />;
	} else {
		return <Redirect to='/login' />;
	}
};

export default PrivateRoute;
