import React from 'react';
import styled from 'styled-components';
import { blLogo, wLogo, bClose } from '../../images';

const Header = ({ whiteLogo, control, controlAction }) => {
	return (
		<H line={whiteLogo}>
			<Logo w={whiteLogo} />
			{control && <Close onClick={controlAction}>Close</Close>}
		</H>
	);
};

export default Header;

Header.defaultProps = {
	controlAction: () => {},
};

const Close = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	width: 55px;
	height: 20px;
	position: relative;

	&::after {
		position: absolute;
		content: '';
		width: 12px;
		height: 12px;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		background-image: url(${bClose});
		background-size: 100%;
		background-repeat: no-repeat;
	}
`;

const H = styled.div`
	padding: 0;
	width: 100%;
	height: 50px;
	flex-shrink: 0;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	&::before {
		position: absolute;
		content: '';
		width: 20%;
		height: 2px;
		top: 28px;
		left: 0;
		background-color: ${({ line }) => (line ? 'rgba(0,0,0,0)' : ' #2E6EFF')};
	}

	&::after {
		position: absolute;
		content: '';
		width: 80%;
		top: 28px;
		left: 20%;
		height: 1px;
		background-color: ${({ line }) => (line ? 'rgba(0,0,0,0)' : '#EFF4FF')};
		@media screen and (max-width: 414px) {
			height: 2px;
		}
	}
`;

const Logo = styled.div`
	width: 109px;
	height: 28px;
	background-image: url(${({ w }) => (w ? wLogo : blLogo)});
	background-repeat: no-repeat;
	background-size: 100%;
`;
