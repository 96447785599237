import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from './reducers';

export default function configureStore(initialState) {
	const sagaMiddleware = createSagaMiddleware();

	const store = createStore(
		rootReducer,
		initialState,
		compose(
			applyMiddleware(sagaMiddleware)
			// window.__REDUX_DEVTOOLS_EXTENSION__ && window. __REDUX_DEVTOOLS_EXTENSION__() //dev
		)
	);
	store.runSaga = sagaMiddleware.run;
	store.close = () => store.dispatch(END);
	return store;
}
