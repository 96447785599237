import styled from 'styled-components';

export const Card = styled.div`
	background-color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 20px 45px rgba(0, 0, 0, 0.06);
`;

export const Space = styled.div`
	width: 100%;
	height: ${({ h }) => h || 0};
	@media screen and (max-width: 414px) {
		height: ${({ mh, h }) => mh || h};
	}
`;

export const Img = styled.div`
	width: ${({ w }) => w || '60px'};
	height: ${({ h }) => h || '60px'};
	background-size: 100%;
	background-image: url(${({ src }) => src});
	background-repeat: no-repeat;
	@media screen and (max-width: 414px) {
		width: ${({ mW }) => mW || '60px'};
		height: ${({ mH }) => mH || '60px'};
	}
`;

export const BG = styled.div`
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, #ffffff 61%, #2e6eff 61%);
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	@media screen and (max-width: 414px) {
		background: linear-gradient(0deg, #ffffff 55%, #2e6eff 55%);
	}
`;
