import React, { useState } from 'react';
import { Vignette, Message, camSize } from './style';
import styled from 'styled-components';
import { Button, Spinner } from '../../components';
import { bReload, bLock } from '../../images';
import axios from 'axios';
import { NEXT_STEP, PREV_STEP, ADD_ERROR, REMOVE_ERROR, SET_AGE_DATA, SET_TIME_DELTA } from './reducer';

const URL = process.env.REACT_APP_AWS_REK_SERVER;

const SendPhoto = ({ dispatch, state, ...props }) => {
	const [verifying, setVerifying] = useState(false);

	const verify = async URL => {
		setVerifying(true);
		try {
			const t0 = performance.now();
			if (!state.b64Img) throw new Error();
			const response = await axios.post(URL, { sourceImage: state.b64Img });
			const t1 = performance.now();
			const delta = Math.round(((t1 - t0) / 1000) * 10) / 10;
			dispatch({ type: SET_TIME_DELTA, delta });
			setVerifying(false);
			dispatch({ type: SET_AGE_DATA, ageData: response.data });
			dispatch({ type: REMOVE_ERROR });
		} catch (error) {
			dispatch({ type: ADD_ERROR });
		}
		dispatch({ type: NEXT_STEP });
	};

	return (
		<>
			<Message>Please take a clear picture of yourself so that we can verify your age</Message>
			<Photo img={state.img} verifying={verifying} />
			<Controls>
				{verifying ? (
					<>
						<Msg>Verifying your age ..</Msg>
						<Spinner />
					</>
				) : (
					<>
						<Button w={'170px'} removeBorder icon={bReload} click={() => dispatch({ type: PREV_STEP })}>
							Retake Photo
						</Button>
						<Button w={'305px'} icon={bLock} click={() => verify(URL)}>
							Proceed
						</Button>
					</>
				)}
			</Controls>
		</>
	);
};

export default SendPhoto;

const Msg = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
`;

const Controls = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	& > button:first-child {
		margin-bottom: 25px;
	}
	& > div:first-child {
		margin-bottom: 10px;
	}
`;

const Photo = styled(Vignette)`
	width: ${camSize.w}px;
	height: ${camSize.h}px;
	display: block;
	overflow: hidden;
	position: relative;
	margin-bottom: ${({ verifying }) => (verifying ? '30px' : '10px')};
	background-image: url(${({ img }) => img});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transform: scaleX(-1);

	@media screen and (max-width: 414px) {
		width: ${camSize.mw}px;
		height: ${camSize.mh}px;
		margin-bottom: 20px;
	}
`;
